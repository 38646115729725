<template>
  <b-row class="justify-content-center py-5">
    <b-col v-if="resultPurchase.responseCode === 0" cols="6">
      <b-alert
        :show="dismissCountDown"
        dismissible
        variant="success"
        @dismissed="dismissCountDown = 0"
        @dismiss-count-down="countDownChanged"
      >
        <h4 class="alert-heading">Gracias por tu compra.</h4>
        <p>
          Espere un momento mientras activamos tu tienda.<br />
          Pronto sera redireccionado a su tienda para para complerar los datos
          basicos y la imagen corporativa de tu tienda.
        </p>
        <hr />
        <p>
          <strong>Activando en : {{ dismissCountDown }}</strong>
        </p>
        <b-progress
          variant="success"
          :max="dismissSecs"
          :value="dismissCountDown"
          height="4px"
        ></b-progress>
      </b-alert>
    </b-col>
    <b-col v-else-if="resultPurchase.responseCode === -1">
      <h1>Rechazo de transacción.</h1>
    </b-col>
    <b-col v-else-if="resultPurchase.responseCode === -2">
      <h1>Transacción debe reintentarse.</h1>
    </b-col>
    <b-col v-else-if="resultPurchase.responseCode === -3">
      <h1>Error en transacción.</h1>
    </b-col>
    <b-col v-else-if="resultPurchase.responseCode === -4">
      <h1>Rechazo de transacción.</h1>
    </b-col>
    <b-col v-else-if="resultPurchase.responseCode === -5">
      <h1>Rechazo por error de tasa.</h1>
    </b-col>
    <b-col v-else-if="resultPurchase.responseCode === -6">
      <h1>Excede cupo máximo mensual.</h1>
    </b-col>
    <b-col v-else-if="resultPurchase.responseCode === -7">
      <h1>Excede límite diario por transacción.</h1>
    </b-col>
    <b-col v-else-if="resultPurchase.responseCode === -8">
      <h1>Rubro no autorizado.</h1>
    </b-col>
  </b-row>
</template>
<script>
export default {
  name: "ThanksShop",
  data: function() {
    return {
      token: this.$route.params,
      resultPurchase: false,
      dismissSecs: 10,
      dismissCountDown: 0,
      showDismissibleAlert: false
    };
  },
  watch: {
    dismissCountDown: function(count) {
      if (count === 0) {
        this.$router.push({ path: `/my-shop` });
      }
    }
  },
  mounted() {
    this.showAlert();
    this.verifyPurchase();
  },
  methods: {
    verifyPurchase() {
      this.$axios
        .post("/api/v1/payment/verify", { token: this.token })
        .then(res => {
          this.resultPurchase = res.data.data;
        })
        .catch(error => {
          console.error(error);
        });
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    }
  }
};
</script>
